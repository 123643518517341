import "../core/index";

class ConnexeaseCRM {
  core;
  originalCartSetStorage;
  originalCallbacks;
  originalIncreaseHeaderCart;
  memberInformation;

  constructor(publicToken) {
    this.core = new window.RegisteredConnexeaseModules.Core(publicToken, {
      debugEnabled: true,
    });
    this.originalCartSetStorage = window.Cart?.setStorage || (() => {});
    this.originalCallbacks = window.callbacks;
    this.originalIncreaseHeaderCart = window.qtyIncreaseHeaderCart;
  }

  get provider() {
    return this.core.providers.TSOFT;
  }

  get isTsoft() {
    // const tsoftCopyrightElement = document.head.querySelector(
    // 	'meta[name="copyright"]',
    // );
    // const contentText = tsoftCopyrightElement?.content?.toLowerCase();

    // return contentText?.includes("t-soft");

    return true;
  }

  async member() {
    try {
      const response = await fetch("/srv/service/customer/get-detail", {
        method: "GET",
      });
      const member = await response.json();

      if (!!member?.mobile_phone === false) {
        console.warn("[CN-CRM] TSoft SDK member not found.");
        return null;
      }

      this.memberInformation = member;

      return member;
    } catch (error) {
      console.error("[CN-CRM] TSoft SDK member error:", error);

      this.memberInformation = null;
    }
  }

  async watch() {
    if (this.core.isDebugEnabled) {
      console.log("[CN-CRM] [DEBUG] Watching TSoft SDK...");
    }

    if (this.isTsoft === false) {
      console.warn("[CN-CRM] TSoft SDK is not detected on this page.");
      return;
    }

    if (window.Cart !== undefined) {
      window.Cart.setStorage = async (...args) => {
        this.saveCartSnapshotV2();
        this.saveFavoriteProductsSnapshotV2();

        const member = await this.member();

        if (member) {
          await this.saveCartSnapshot(1000);
          await this.saveFavoriteProductsSnapshot(1000);
        }

        this.originalCartSetStorage(...args);
      };
    }

    if (window.callbacks !== undefined) {
      const tempCallbacks = window.callbacks;

      const addFunctions = tempCallbacks?.cart?.add || [];

      if (addFunctions.length > 0) {
        tempCallbacks.cart.add = tempCallbacks.cart.add.map((originalFn) => {
          return async (...args) => {
            this.saveCartSnapshotV2();
            this.saveFavoriteProductsSnapshotV2();

            const member = await this.member();

            if (member) {
              await this.saveCartSnapshot(1000);
              await this.saveFavoriteProductsSnapshot(1000);
            }

            originalFn(...args);
          };
        });

        window.callbacks = tempCallbacks;
      }
    }

    if (window.qtyIncreaseHeaderCart !== undefined) {
      window.qtyIncreaseHeaderCart = async (...args) => {
        this.saveCartSnapshotV2();
        this.saveFavoriteProductsSnapshotV2();

        const member = await this.member();

        if (member) {
          await this.saveCartSnapshot(1000);
          await this.saveFavoriteProductsSnapshot(1000);
        }
        this.originalIncreaseHeaderCart(...args);
      };
    }

    TsoftAppsOrderApproveCallback(async (order) => {
      this.saveCheckoutV2();

      const member = await this.member();

      if (!member) {
        return;
      }

      const products = order?.products.map((product) =>
        this.mapProduct(product)
      );
      const cartTotal = order?.amount?.toString() || "0";
      const tax = order?.total_vat?.toString() || null;
      const orderNumber = order?.transaction?.toString() || null;

      const payload = {
        total: {
          cartTotal,
          orderNumber,
          ...(tax ? { tax } : {}),
        },
      };

      this.saveEvent(products, this.core.eventTypes.CHECKOUT, payload);
    });

    const corePath = this.core.path?.toString().toLowerCase();

    if (
      ["sepet", "uye-kayit", "uye-girisi-sayfasi", "order"].includes(
        corePath
      ) ||
      corePath === "/"
    ) {
      this.saveCartSnapshotV2();
      this.saveFavoriteProductsSnapshotV2();

      const member = await this.member();

      if (member) {
        if (corePath === "/order") {
          await this.saveCartSnapshot(1000);
          await this.saveFavoriteProductsSnapshot(1000);
        } else {
          await this.saveCartSnapshot();
          await this.saveFavoriteProductsSnapshot();
        }
      }
    }
  }

  async saveCartSnapshot(ms = 0) {
    setTimeout(async () => {
      const response = await fetch("/srv/service/cart/load?link=sepet", {
        method: "GET",
      });

      const data = await response.json();

      const products =
        data?.PRODUCTS?.map((product) => ({
          productId: product?.ID?.toString(),
          variantId: product?.VARIANT_ID?.toString(),
          name: product?.TITLE?.toString(),
          price: (product?.PRICE_TOTAL / product?.COUNT)?.toString(),
          totalPrice: product?.PRICE_TOTAL?.toString(),
          brand: product?.BRAND,
          category: product?.CATEGORY_NAME,
          quantity: product?.COUNT,
        })) ?? [];

      const cartTotal = data?.PRICE_GENERAL?.toString();

      const payload = {
        total: {
          cartTotal,
        },
      };

      this.saveEvent(products, this.core.eventTypes.CART_SNAPSHOT, payload);
    }, ms);
  }

  saveEvent(mappedProduct, eventType, payload = {}) {
    const hasCartTotal =
      payload?.total?.cartTotal !== undefined &&
      payload?.total?.cartTotal !== null;

    const eventBody = {
      ...this.memberPayload,
      ...(hasCartTotal ? { cartTotal: payload.total.cartTotal } : {}),
      event: eventType,
      ...(Array.isArray(mappedProduct)
        ? { products: mappedProduct }
        : { product: mappedProduct }),
      ...payload,
    };

    this.core.saveEvent(eventType, eventBody);
  }

  saveEventV2(eventType) {
    const email = window?.MEMBER_INFO?.MAIL;

    if (!email) {
      return;
    }

    this.core.saveEventV2({
      memberId: this.core.stringToHash(email),
      eventType: this.core.mapEventTypeV2(eventType),
      provider: 2,
      siteUrl: location.host,
      memberToken: document.cookie,
    });
  }

  async saveCheckoutV2() {
    this.saveEventV2(this.core.eventTypes.CHECKOUT);
  }

  async saveCartSnapshotV2() {
    this.saveEventV2(this.core.eventTypes.CART_SNAPSHOT);
  }

  async saveFavoriteProductsSnapshotV2() {
    this.saveEventV2(this.core.eventTypes.FAVORITE_PRODUCTS_SNAPSHOT);
  }

  mapProduct(product = {}) {
    return {
      name: product?.name,
      productId: (product?.identifier ?? product?.id)?.toString(),
      variantId: (product?.variant_name ?? product?.variant)?.toString(),
      price: (product?.total_amount ?? product?.price)?.toString(),
      brand: product?.brand,
      category: product?.category,
      quantity: Number.parseInt(product?.quantity ?? 1),
    };
  }

  get memberPayload() {
    return {
      userId: this.currentUserId,
      userEmail: this.memberInformation?.email,
      userPhone: this.formatPhoneNumber(this.memberInformation?.mobile_phone),
      userFullName: `${this.memberInformation?.name ?? ""} ${
        this.memberInformation?.surname ?? ""
      }`,
      provider: this.provider,
      website: this.core.host,
    };
  }

  get currentUserId() {
    return (
      (
        window?.getUserID &&
        typeof window.getUserID === "function" &&
        window.getUserID()
      )?.toString() || this.core.stringToHash(this.memberInformation?.email)
    );
  }

  formatPhoneNumber(val) {
    return val.replace(/\D/g, "");
  }

  async memberFavoriteProducts() {
    try {
      const response = await fetch("/srv/service/profile/get-shopping-list", {
        method: "GET",
      }).then((res) => res.json());

      if (response?.isError === true) {
        console.warn("[CN-CRM] TSoft SDK favorite products not found.");
        return;
      }

      if (response === undefined) {
        console.warn("[CN-CRM] TSoft SDK favorite products not found.");
        return;
      }

      return (response?.CATEGORIES?.map((c) => c.PRODUCTS || []) || []).flat();
    } catch (error) {
      console.error(
        "[CN-CRM] TSoft SDK failed to fetch favorite products:",
        error
      );

      return;
    }
  }

  async saveFavoriteProductsSnapshot(ms = 0) {
    setTimeout(async () => {
      const favoriteProducts = await this.memberFavoriteProducts();

      if (!favoriteProducts) {
        return;
      }

      const mappedProducts =
        favoriteProducts?.map((rawProduct) => ({
          productId: rawProduct?.ID?.toString(),
          variantId: rawProduct?.VARIANT_ID?.toString(),
          name: rawProduct?.TITLE,
          price: rawProduct?.PRICE_SELL?.toString(),
          brand: rawProduct?.BRAND,
          category: "",
        })) ?? [];

      this.saveEvent(
        mappedProducts,
        this.core.eventTypes.FAVORITE_PRODUCTS_SNAPSHOT
      );
    }, ms);
  }
}

window.RegisteredConnexeaseModules = {
  ...window.RegisteredConnexeaseModules,
  ConnexeaseCRM,
};
